<template>
  <product-form :is-edit="false"></product-form>
</template>
<script>
import ProductForm from './ProductForm';

export default {
  name: 'addProduct',
  components: { ProductForm },
};
</script>
<style>
</style>
